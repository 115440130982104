<template>
  <section id="schedule-resume" class="vw-100 mb-5 ml-0">
    <h2 class="mb-2">Resumo da agenda</h2>
    <Filters 
      @update="setFilters"
    />
    <Table
      :appointments="appointments"
      :loading="isLoading"
      @openAppointmentModal="handleOpenModal"
      @onOrdenate="handleOrder"
    />
    <div class="pagination-position">
			<b-pagination 
        v-model="page" 
        :total-rows="count" 
        :per-page="limit" 
        first-number 
        last-number 
        size="sm" 
      />
		</div>
    <AppointmentModal
      :appointmentId="appointmentId"
      @loadAppointments="onUpdate"
    />
  </section>
</template>

<script>
import { getFilterSituation } from '@/utils/filtersLocalStorageManager'

export default {
  name: 'ScheduleResume',

  components: {
    Filters: () => import('./ScheduleFilters.vue'),
    Table: () => import('./ScheduleTable.vue'),
    AppointmentModal: () => import('@/components/AppointmentModal')
  },

  data() {
		return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
			page: 1,
			limit: 0,
			count: 0,
			appointments: [],
      filters: {
        order: {
          date: 'asc'
        },
        situation: getFilterSituation() ? getFilterSituation().map((filter) => { return filter.value}) : null
      },
      isLoading: false,
      appointmentId: null,
		}
	},

  async created () {
    await this.getAppointmentsResume()
  },

  methods: {
    setFilters (value) {
      const filterData = JSON.parse(JSON.stringify(value))
      filterData.situation = value?.situation?.map(v => v.value)
      filterData.priorityLevel = value?.priorityLevel?.map(v => v.value)
      Object.keys(filterData).map(key => {
        this.$set(this.filters, key, filterData[key])
      })
    },
    async onUpdate () {
      await this.getAppointmentsResume()
      this.$emit('update')
    },
    async getAppointmentsResume () {
      try {
        this.isLoading = true
        if(!this.filters?.healthPlans?.length) this.filters.healthPlans = null
        if(!this.filters?.procedures?.length) this.filters.procedures = null
        if(this.filters?.situation?.includes(null)) this.filters.situation = null
        
        const res = await this.api.getAppointmentsResume(this.clinic.id, this.page, this.filters)

        this.appointments = res.data.data;
        this.limit = res.data.per_page;
        this.count = res.data.total;
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.isLoading = false
      }
    },
    handleOpenModal(appointmentId){
      this.appointmentId = appointmentId
      this.$bvModal.show('appointment-modal')
    },
    handleOrder (value) {
      this.$set(this.filters, 'order', value)
    },
    setAppointmentId(id){
      this.appointmentId = id
    }
  },
  watch: {
		page: async function () { 
      await this.getAppointmentsResume()
    },
    filters: {
      async handler () {
        await this.getAppointmentsResume()
      }, deep: true
    }
	}
}

</script>

<style lang="scss">
#schedule-resume {
  margin: 4px auto;
  padding: 30px !important;
  background-color: var(--neutral-000);
  border-radius: 8px;
  max-width: calc(100% - 16px);

   h2 {
    font-size: min(1.2vw, 18px);
    color: var(--dark-blue);
    font-weight: 700;
   }
}
</style>